/**
 * A module made to abstract away some of the google analytics / google tag manager boilerplate code
 */

import { bbaiCYOAPromptStepStatus } from "../constants/bbai.prompts"

// import { bbaiPromptStepStatus } from "../constants/bbai.prompts";

/* **************
 * definitions / types / interfaces
 */

type Primitive = string|number|boolean|null|undefined

export type ExtraProps = {
  [key:string]: Primitive
}

/* **************
 * config / settings
 */
const COOLDOWN_PERIOD_MS = 5*1000 // 5 seconds
const DEBUG_VERBOSE = false
const CONVERT_UNDEFINED_TO_NULL = true

// const STEP_TO_EVENT_MAP:Partial<Record<bbaiPromptStepStatus,string>> = {
//   [bbaiPromptStepStatus.INITIAL]: "character_description",
//   [bbaiPromptStepStatus.ENEMY]: "enemy_description",
//   [bbaiPromptStepStatus.BACKGROUND]: "background_description",
//   [bbaiPromptStepStatus.BUILD_BBDOC]: "generating_bbdoc",
//   [bbaiPromptStepStatus.CLAIM_ACCOUNT]: "select_claim_account",
//   [bbaiPromptStepStatus.LOGIN_ACCOUNT]: "select_login_account",
//   [bbaiPromptStepStatus.DOWNLOAD_BBDOC]: "bbdoc_download",
//   [bbaiPromptStepStatus.COMPLETE]: "complete"
// }

/* **************
 * module state
 */
const recentlySent:Record<string,number> = {}

/* **************
 * module internal functions
 */

function debugLogDebug(...args:string[]):void {
  if (DEBUG_VERBOSE) {
    console.debug(...args)
  }
}

/**
 * Convert a primitive to a string
 * @param value 
 * @returns 
 */
function toString(value:Primitive):string {
  if (value === null) {
    return "null"
  }
  else if (value === undefined && !CONVERT_UNDEFINED_TO_NULL) {
    return "null"
  }
  else if (value === undefined && CONVERT_UNDEFINED_TO_NULL) {
    return "undefined"
  }
  else if (typeof value === "boolean") {
    return value? "true" : "false"
  }
  else if (typeof value === "number") {
    return value.toString(10)
  }
  else {
    // in theory, only strings will land here given the parameter type, but adding the ""+ just in case
    return ""+value
  }
}

/**
 * Convert event props into a unique string key for caching/indexing
 * @param eventName 
 * @param extraProps 
 * @returns 
 */
function makeEventKey(eventName:string, extraProps?:ExtraProps): string {
  if (extraProps === undefined) {
    return eventName
  }
  const extraPropsStr = Object.entries(extraProps)
    .map(([key, value]) => `${toString(key)}:${toString(value)}`)
    .sort() // sort in case an identical object has keys in a different order
    .join(`,`)

  return `${eventName}: {${extraPropsStr}}`
}

/* **************
 * module external functions
 */

/**
 * Send a GTM custom event (if configured correctly on the dash - will be transformed/piped into GA4)
 * @param eventName 
 * @param extraProps 
 * @returns 
 */
export function sendCustomEvent(eventName:string, extraProps?:ExtraProps):void {
  const dataLayer = (window as any).dataLayer
  if (!Array.isArray(dataLayer)) {
    debugLogDebug(`[GoogleAnalyticsWrapper] dataLayer not present - ignoring "${eventName}" event`)
    return
  }
  const eventKey = makeEventKey(eventName, extraProps)
  if (!(eventKey in recentlySent) || Date.now() - recentlySent[eventKey] >= COOLDOWN_PERIOD_MS) {
    // https://developers.google.com/tag-platform/tag-manager/datalayer
    debugLogDebug(`[GoogleAnalyticsWrapper] sending ${eventName}`)
    dataLayer.push({
      event: eventName,
      ...extraProps
    })
  }
  else {
    debugLogDebug(`[GoogleAnalyticsWrapper] recently fired event identical to ${eventName} - skipping.`)
    debugLogDebug(`[GoogleAnalyticsWrapper] Full key of identical event ${eventKey}`)
  }
}

// TODO: something for CYOA here?
export function handlePromptStepAnalytics(step:bbaiCYOAPromptStepStatus, userInput?:Primitive):void {
  const eventName = "" //STEP_TO_EVENT_MAP[step]
  if (eventName === undefined) {
    console.error(`[GoogleAnalytics] no corresponding analytics event found for step ${step}`)
    return
  }
  let extraProps:ExtraProps|undefined
  if (userInput !== undefined) {
    extraProps = {
      USER_INPUT: userInput
    }
  }
  sendCustomEvent(eventName, extraProps)
}
