import { ReactElement, useEffect, useState } from 'react'
import { bbaiCYOAPromptOptions, bbaiCYOAPromptStepStatus, bbaiUser, defaultUser } from '../../../constants/bbai.prompts'
import PromptMessage from './PromptMessage'
import useNewCYOAStore from '../../../newCYOAStore'
import LoginPrompt from './LoginPrompt'
import { BBPlatformClient } from '../../../api/BBPlatform'
import ErrorOutOfCreditsCYOA from './ErrorOutOfCreditsCYOA'

const InitialCYOAPrompt = (): ReactElement  => {
  // const bbaiStore = state'
  const [userStoryPrompt, setUserStoryPrompt] = useState<string>("")
  const [notEnoughCreditsError, setNotEnoughCreditsError] = useState<boolean>(false)

  const bbaiStore = useNewCYOAStore()

  useEffect(() => {
    if(bbaiStore.userStoryPrompt.length > 0){
      setUserStoryPrompt(bbaiStore.userStoryPrompt)
      if(bbaiStore.isAuthed && !bbaiStore.completed){
        CheckIfUserHasEnoughCredits()
      }
    } else {
      setUserStoryPrompt("")
    }
    // eslint-disable-next-line
  }, [bbaiStore.userStoryPrompt, bbaiStore.isAuthed, notEnoughCreditsError, bbaiStore.gameDataRestartID])

  const CheckIfUserHasEnoughCredits = async() => {
    try{
      const userCreditsInfo = await BBPlatformClient.getBalance()
  
      if (userCreditsInfo.balance < 1) {
        setNotEnoughCreditsError(true)
  
        // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)  
      }
  
      bbaiStore.nextStage()

    }catch(error){
      console.error("error checking user credits", error)
       bbaiStore.setErrorState({
          title: "Error ran out of Story Game credts",
          message: "You have run out of Story Game credits. Please purchase more credits to continue.",
        });
    }
  }

  return (
    <>
    <PromptMessage promptStage={bbaiCYOAPromptStepStatus.INITIAL} message={bbaiCYOAPromptOptions.INITIAL.prompt} user={bbaiUser}
    />

    { userStoryPrompt.length > 0 && <PromptMessage promptStage={bbaiCYOAPromptStepStatus.INITIAL} message={userStoryPrompt} user={defaultUser}
      />
    }
    {notEnoughCreditsError && <ErrorOutOfCreditsCYOA/> }

    { userStoryPrompt.length > 0 && !bbaiStore.isAuthed && <PromptMessage promptStage={bbaiCYOAPromptStepStatus.INITIAL} component={<LoginPrompt />} message={""} user={bbaiUser}
    /> }
    </>
  )
}

export default InitialCYOAPrompt