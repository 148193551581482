export const bbaiUser = {
  id: "1",
  pic: "https://frontend-assets.buildbox.com/web-gen-ai/boxy_avatar.svg",
};

export const defaultUser = {
  id: "2",
  pic: "https://frontend-assets.buildbox.com/web-gen-ai/user_avatar.svg",
};


export enum bbaiCYOAPromptStepStatus {
  INITIAL = "INITIAL",
  GENERATING_GAME_DATA = "GENERATING_GAME_DATA",
  GENERATING_GAME_ASSETS = "GENERATING_GAME_ASSETS",
  BUILD_BBDOC = "BUILD_BBDOC",
  DOWNLOAD_BBDOC = "DOWNLOAD_BBDOC",
  CLAIM_ACCOUNT = "CLAIM_ACCOUNT",
  COMPLETE = "COMPLETE",
  CLAIM_LOGIN_SKIP = "CLAIM_LOGIN_SKIP",
  LOGIN_ACCOUNT = "LOGIN_ACCOUNT",
  DONE = "DONE",
  GAME_DATA = "GAME_DATA",
  ERROR_RESTART_REGENERATE = "ERROR_RESTART_REGENERATE",
}


export const bbaiCYOAPromptOptions: Record<
  string,
  { prompt: string; errPrompt: string }
> = {
  // INITIAL: {
  //   prompt: "Hello, I'm BBAI from Buildbox, and I'm here to help you make your first game! What story do you want to tell?",
  //   errPrompt: "I'm sorry, I didn't quite get that. Could you tell me more about your story?",
  // },
  INITIAL: {
    prompt: "Hello, I'm BBAI from Buildbox, thank you for spending time with me making thousands and thousands of Story Games. Click \"Learn More\" above to see how you can still enjoy Story Games in Buildbox Classic!",
    errPrompt: "I'm sorry, I didn't quite get that. Could you tell me more about your story?",
  },  
  GAME_DATA: {
    prompt: "What story do you want to tell?",
    errPrompt: "I'm sorry, I was unable to generate your game (something went wrong). Please try again.",
  },  
  GENERATING_GAME_DATA: {
    prompt: "That's a great idea! I'm writing your Story Game now, this will take a minute or two...",
    errPrompt: "I'm sorry, I was unable to generate your game (something went wrong). Please try again.",
  },  
  GENERATING_GAME_ASSETS: {
    prompt: "I finished writing your Story Game! Now I'm working on adding graphics and sounds. This will take a minute or two...",
    errPrompt: "I'm sorry, I was unable to generate your game (something went wrong). Please try again.",
  },
  BUILD_BBDOC: {
    prompt: "Just one more moment, I'm putting the full game together!",
    errPrompt: "I'm sorry, I was unable to construct your game (something went wrong). Please try again."
  },
  CLAIM_LOGIN_SKIP: {
    prompt: "",
    errPrompt: "Error: I'm sorry, I didn't quite get that. Please select CLAIM, LOGIN, or SKIP.",
  },
  COMPLETE: {
    prompt: "You can also download your game project as well as Buildbox to start modding it!",
    errPrompt: "Error while trying to download BBDoc",
  },
  COMPLETE_MOBILE: {
    prompt: "Play your bit now on Buildbox World!",
    errPrompt: "something went wrong when completing the game",
  },
  DOWNLOAD_BBDOC: {
    prompt: "I have finished uploading your game and it is now ready to play on the Buildbox World app!",
    errPrompt: "Error while trying to download BBDoc",
  },
  CLAIM_ACCOUNT: {
    prompt: "I have finished uploading your game and it is now ready to play on the Buildbox World app!",
    errPrompt: "We were unable to claim your account and bbdoc, try skipping and manually claiming your game!",
  },
};

export enum AssetType {
  CHARACTER = "CHARACTER",
  ENEMY = "ENEMY",
  BACKGROUND = "BACKGROUND",
}

export type Display = {
  Component: React.ReactElement
  stage: number// maybe make this string avlue?
  nextStage: string
}