import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { BBPlatformClient } from "./api/BBPlatform";
import {
  bbaiCYOAPromptStepStatus,
  Display,
} from "./constants/bbai.prompts";

import { 
  BBDocResponseObj, 
} from "./constants/GenPrompTypes";
import { v4 as uuidv4 } from "uuid";
import { mapPromptToDisplay } from "./constants/mapPromptToDisplay";
import { BBAI_STORE_VERSION, storeStorageVersion } from "./utils/StorageVersion";

export const CYOAProjectType = "cyoa";

const BBAI_STORAGE_KEY = "bbaiCyoa-Store";


storeStorageVersion(BBAI_STORE_VERSION, BBAI_STORAGE_KEY);

// localStorage.removeItem(BBAI_STORAGE_KEY);

interface errorBody {
  title: string;
  message: string;
}

export type CYOAState = {
  projectType: string;
  version: number;
  isLoading: boolean; 
  completed?: boolean; 
  error?: errorBody; 
  promptStepStatus: bbaiCYOAPromptStepStatus;
  userStoryPrompt: string;
  generatedCYOAGameData: any | null;
  projectId: string;
  allAssetsGenerated: boolean;
  bbworldBBDocId: string; 
  isGuest: boolean;
  bbDocHistory: BBDocResponseObj[]|null
  // promptComponents: Display[] //React.ReactNode[]; //
  showAuthFrame: boolean;
  isAuthed: boolean;
  createAccountMode: boolean;
  userId: string | null;
  bbDocUrl: string | null;
  gameDataRestartID: string | null;
};

export type CYOAAction = {
  loadingState: (loading: boolean) => void;
  setPolling: (polling: boolean) => void;
  initial: () => void;
  setErrorState: (error: errorBody | undefined) => void;
  setPromptStepState: (stepStatus: bbaiCYOAPromptStepStatus) => void;
  setUserStoryPrompt: (prompt: string) => void;
  setGeneratedCYOAGameData: (data: object | null) => void;
  setProjectId: (id: string) => void;
  setAllAssetsGenerated: (allAssetsGenerated: boolean) => void;
  setBBWorldBBDocId: (id: string) => void;
  setBBDocHistory: (history:BBDocResponseObj[]|null) => void;
  setGuest: (isGuest: boolean) => void;
  nextStage: () => void;
  setStage: (stage: bbaiCYOAPromptStepStatus) => void;
  setShowAuthFrame: (showAuthFrame: boolean) => void;
  setIsAuthed: (isAuthed: boolean) => void;
  setCreateAccountMode: (createAccountMode: boolean) => void;
  setUserId: (userId: string) => void;
  setBBDocUrl: (url: string ) => void;
  startOver: () => void;
  setRetryID: (id: string | null) => void;
  getComponentsArray: () => React.ReactElement[];
  setConfirmComplete: () => void;
};

export const initialNewCYOAState: CYOAState = {
  projectType: CYOAProjectType,
  version: BBAI_STORE_VERSION,
  isLoading: false,
  error: undefined,
  promptStepStatus: bbaiCYOAPromptStepStatus.INITIAL,
  userStoryPrompt: "",
  generatedCYOAGameData: null,
  allAssetsGenerated: false,
  projectId: uuidv4(), // doesn't seeem to generate correctly
  bbworldBBDocId: "",
  completed: false,
  isGuest: true,
  bbDocHistory: null,
  // promptComponents: [mapPromptToDisplay["INITIAL"]],
  showAuthFrame: false,
  isAuthed: false,
  createAccountMode: false,
  userId: null,
  bbDocUrl: null,
  gameDataRestartID: null,
}; 

//TODO: too many repeated function, refactor
const useNewCYOAStore = create<CYOAState & CYOAAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialNewCYOAState,
        loadingState: (loading: boolean) =>
          set((state) => ({
            ...state,
            isLoading: loading,
          })),
        setPolling: (polling: boolean) =>
          set((state) => ({
            ...state,
            isLoading: true,
            polling: polling,
          })),
        initial: () => {

          const guest = localStorage.getItem("isGuest");

          if (guest === null) {
            localStorage.setItem("isGuest", "true");
            set({ ...initialNewCYOAState, 
              isGuest: true })
          }  else if( BBPlatformClient.isBBWorldMode()) {
            set({ ...initialNewCYOAState, 
              isGuest: false })
          } else { 
            if (guest === "false") {
              set({ ...initialNewCYOAState, 
                isGuest: false })
            }
            else {
              set({ ...initialNewCYOAState, 
                isGuest: true })
            }
          }
        },
        setPromptStepState: (stepStatus: bbaiCYOAPromptStepStatus) =>
          set((state) => ({
            ...state,
            promptStepStatus: stepStatus,
          })),
        setUserStoryPrompt: (prompt: string) =>
          set((state) => ({
            ...state,
            userStoryPrompt: prompt,
          })),
        setGeneratedCYOAGameData: (data: any) =>
          set((state) => ({
            ...state,
            generatedCYOAGameData: data,
          })),
        setProjectId: (id: string) =>
          set((state) => ({
            ...state,
            projectId: id,
          })),
        setAllAssetsGenerated: (val: boolean) =>
          set((state) => ({
            ...state,
            allAssetsGenerated: val,
          })),          
        setErrorState: (error: errorBody | undefined) =>
          set((state) => ({
            ...state,
            error: error,
          })),
        setBBWorldBBDocId: (id) =>
          set((state) => ({
            ...state,
            bbworldBBDocId: id,
          })),
        setBBDocHistory: (bbDocHistory:BBDocResponseObj[]|null) =>
          set((state) => ({
            ...state,
            bbDocHistory
          })),
        setGuest: (isGuest: boolean) =>
          set((state) => ({
            ...state,
            isGuest: isGuest,
          })),
        nextStage: () => {
          const currentStage = get().promptStepStatus
          const nextStage = mapPromptToDisplay[bbaiCYOAPromptStepStatus[currentStage]]?.nextStage

          set((state) => (state.isAuthed ? {
            ...state,
            promptStepStatus: bbaiCYOAPromptStepStatus[nextStage as bbaiCYOAPromptStepStatus],
          } : {...state}))
        },
        setStage: (stage: bbaiCYOAPromptStepStatus) => {
          let newComponentArray:Display[] = []

          for (const [key, value] of Object.entries(mapPromptToDisplay)) {
            newComponentArray.push(value)
            if(key === stage) {
              break;
            }    
          }   

          set((state) => ({
              ...state,
              promptStepStatus: stage,
              promptComponents: newComponentArray,
          }))
        },
        setShowAuthFrame: (showAuthFrame: boolean) =>
          set((state) => ({
            ...state,
            showAuthFrame: showAuthFrame,
          })),
        setIsAuthed: (isAuthed: boolean) =>
          set((state) => ({
            ...state,
            isAuthed: isAuthed,
          })),
        setCreateAccountMode: (createAccountMode: boolean) =>
          set((state) => ({
            ...state,
            createAccountMode: createAccountMode,
          })),
        setUserId: (userId: string) =>{
          set((state) => ({
            ...state,
            userId: userId,
          }))
        },
        setBBDocUrl: (url: string) => {
          set((state) => ({
            ...state,
            bbDocUrl: url
          }))
        },
        startOver: () => {
          set(state => (
            {
              ...initialNewCYOAState,
              isAuthed: state.isAuthed,
            }
          ))
        },
        setRetryID: (id: string | null) => {
          console.log("setRestartID", id);
          let newComponentArray:Display[] = []

          for (const [key, value] of Object.entries(mapPromptToDisplay)) {
            newComponentArray.push(value)
            if(key === bbaiCYOAPromptStepStatus.INITIAL) {
              break;
            }    
          }
          
          set((state) => {
            
            return {
              ...initialNewCYOAState,
              gameDataRestartID: id,
              error: undefined,
              promptStepStatus: bbaiCYOAPromptStepStatus.INITIAL,
              userStoryPrompt: state.userStoryPrompt,
              promptComponents: newComponentArray,
              isAuthed: state.isAuthed,
            }
          })
        },
        getComponentsArray: ():React.ReactElement[] => {
          let newComponentArray:React.ReactElement[] = []

          // // let passed = false;

          // console.log("next stage ", nextStage)
          for (const [key, value] of Object.entries(mapPromptToDisplay)) {
            newComponentArray.push(value.Component)
            if(key === get().promptStepStatus) {
              break;
            }    
          }  
          
          return newComponentArray 
        },
        setConfirmComplete: () => {
          set((state) => ({
            ...state,
            completed: true,
          }))
        }
      }),
      {
        name: "bbaiCyoa-Store", // unique name
        storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);



export default useNewCYOAStore;
