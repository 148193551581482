import { useEffect, useCallback, useState, useRef, ReactElement, Children} from "react"
import classnames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";
import { bbaiCYOAPromptStepStatus } from "../../../constants/bbai.prompts";
import { Form, Input, InputRef, Spin } from "antd";
import styles from "../../../styles/PlaceholderFormComponent.module.css";
// import { BBDocList } from "../../../components/BBDocList";
import LoginStatus from "../../../components/LoginStatus";
import { BBPlatformClient } from "../../../api/BBPlatform";
import { validateLength } from "../../../utils/validationForm";
import useNewCYOAStore, { CYOAProjectType } from "../../../newCYOAStore";
import ErrorRestartRegenerateMessage from "./ErrorRestartRegenerateMessage";
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface Props {
  store?: any
  onClaimRequest: (createAccountMode: boolean) => void;
  propagateLogout?: () => void;
}
const PromptForm = (props: Props) => {

  const {  onClaimRequest, propagateLogout } = props;
  
  const bbaiStore = useNewCYOAStore();

  const bottomRef = useRef<null | HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState<boolean>(isMobile ? false : true);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => { }, [bbaiStore.promptStepStatus])

  const blurKeyboard = () => {
    // we need to wait a frame as react is likley mid-rendering when this is called
    requestAnimationFrame(() => {
      const active = document.activeElement as HTMLElement | null;
      if (active !== null) {
        active.blur();
      } else {
        console.warn(
          "[blurKeyboard] activeElement is null - cant blur keyboard"
        );
      }
    });
  };

  const handleKeyDown = async (event: any) => {
    if (bbaiStore.isLoading || bbaiStore.completed ) {
      return;
    }
    if (event.key === "Enter") {
      handleUserInput(event.target.value);
    }
  };

  const handleSendClick = async (event: any) => {
    if (bbaiStore.isLoading || bbaiStore.completed ) {
      return;
    }
    handleUserInput(inputValue);
  };

  const hasEmptyScrollSpace = () => {
    const scrollParent = bottomRef?.current?.parentElement;
    return (
      scrollParent !== undefined &&
      scrollParent !== null &&
      scrollParent.scrollHeight <= scrollParent.clientHeight
    );
  };

  const scrollIntoView = function () {
    if (!hasEmptyScrollSpace()) {
      // don't scroll if the keyboard is open - it does weird stuff on iOS
      bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const onChange = (event: any) => {
    if (bbaiStore.isLoading || bbaiStore.completed) {
      return;
    }
    setInputValue(event.target.value);
  };

  const handleUserInput = (value: any) => {
    const passed = validateLength(value);
    if (!passed) {
      // invalid user input, show error prompt for step
      return
    }
    if (bbaiStore.projectType === CYOAProjectType) {
      // console.log("CYOA user input logic")
      if (bbaiCYOAPromptStepStatus.INITIAL === bbaiStore.promptStepStatus) {
        // console.log("in initial state, got ", value, " for user story prompt")
        bbaiStore.setUserStoryPrompt(value);

      }
      else {
        console.error("ERROR: unexpected user input to CYOA logic in state ", bbaiStore.promptStepStatus)
      }
    }
    else {
      // TODO: error handling, what to do here? clear & restart somehow?
      console.error("ERROR: UNKNOWN PROJECT TYPE")
    }
    setInputValue("");

    console.log("USER INPUT2", isMobile, bbaiStore.promptStepStatus)
    if (
      isMobile
      // isMobile &&
      // bbaiStore.promptStepStatus !== bbaiCYOAPromptStepStatus.INITIAL 
    ) {
      console.debug("blur keyboard going off")
      blurKeyboard();
    }
  };

  useEffect(() => {
    scrollIntoView();
    // eslint-disable-next-line
  }, [bbaiStore.promptStepStatus, 
    bbaiStore.bbDocUrl]);
  
  const formRender = useCallback(() => {
    const shouldPositionFlexEnd =
      isMobile && inputFocused && hasEmptyScrollSpace();
    const textInputDisabled =
      bbaiStore.promptStepStatus !== bbaiCYOAPromptStepStatus.INITIAL

    let inputSuffix: ReactElement | undefined;
    if (bbaiStore.isLoading) {
      inputSuffix = <Spin indicator={antIcon} />;
    } else if (textInputDisabled) {
      inputSuffix = (
        <img
          className={classnames(styles.sendIcon, styles.disabled)}
          width={18}
          height={18}
          src="https://frontend-assets.buildbox.com/web-gen-ai/send_button.svg"
          alt="Send Button. Disabled because there is nothing to send at this time."
        />
      );
    } else {
      inputSuffix = (
        <img
          className={styles.sendIcon}
          width={18}
          height={18}
          onClick={handleSendClick}
          src="https://frontend-assets.buildbox.com/web-gen-ai/send_button.svg"
          alt="Send Button"
        />
      );
    }

    // const showBBDocList = !BBPlatformClient.isBBWorldMode()

    const showLogin = !BBPlatformClient.isBBWorldMode()

    // const createComponentArray = () => {
      
    // }
    const arryComponents = Children.toArray(bbaiStore.getComponentsArray())

    return (
      <Form
        className={classnames({
          [styles.promptForm]: !isMobile,
          [styles.promptFormMobile]: isMobile,
        })}
      >
        <>
          {/* {showBBDocList ? <BBDocList style={{ position: "absolute" }} /> : <div style={{ height: "2.5em" }} />} */}
          {
            showLogin ?
              <div className={styles.loginUserDetails}>
                <LoginStatus onLogin={() => onClaimRequest(false)} propagateLogout={propagateLogout} />
              </div>
              :
              undefined
          }
        </>
        <div className={styles.roboManContainer}>
          <img
            src="https://frontend-assets.buildbox.com/web-gen-ai/boxy_logo.webp"
            alt="robo"
            className={styles.roboMan}
          />
        </div>

        <div
          className={classnames(styles.promptFeedback, {
            [styles.justifyEnd]: shouldPositionFlexEnd,
          })}
        >
          {
            arryComponents.map((elm, idx: any) => 
              <div key={idx}>
                {elm}
              </div>
            )
          }
          {bbaiStore.error !== undefined && <ErrorRestartRegenerateMessage/>}
          <div ref={bottomRef} />
        </div>

        {/* input field used to go here */}
      </Form>
    );
    // eslint-disable-next-line
  }, [
    bottomRef,
    inputValue,
    bbaiStore.isLoading,
    bbaiStore.bbworldBBDocId,
    isMobile,
    inputFocused,
    bbaiStore.promptStepStatus,
    bbaiStore,
    bbaiStore.bbDocUrl,
    bbaiStore.error
  ]);

  // console.log("rendering prompt form", bbaiStore.promptStepStatus, bbaiStore.projectType, bbaiStore.promptComponents)

  return (
    <>
     {formRender()}
    </>
  )
}

export default PromptForm