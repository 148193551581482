import { AuthResult } from "bb-auth-frame/component";
import Env from "../Env";

import { 
  BBPlatformClient, 
  BBPlatformClientError, 
  RequestProps, 
  TimestampedTokens,
  BBPlatformClientNotAuthorizedError,
  BBPlatform4XXError,
  BBPlatformServerError,

} from "./BBPlatform";


/** ************************************************************************************************
 * Wrapper class for BBAIPlatform service endpoints
 *
 * BB AI Platform is less mature than BBPlatform so much functionality is simpler or missing entierly
 * this class treats itself as reliant on BBPlatformClient behavior and respects how it handles
 * tokens, errors, etc.
 * Like its brethren it acts as a singleton for convenience
 ************************************************************************************************* */
export class BBAIPlatformClient {
  static tokens: TimestampedTokens | null = null;

  /* ***************************************************************************
   * generic platform-API functions below
   ************************************************************************** */
  static setTokens(tokens: (AuthResult & { timestamp: number }) | null): void {
    if (tokens !== null) {
      BBAIPlatformClient.tokens = tokens;
    } else {
      BBAIPlatformClient.tokens = null;
    }
  }

  static getUnexpiredTokens(): TimestampedTokens | null {
    return BBAIPlatformClient.getUnexpiredTokens()
  }

  static isBBWorldMode(): boolean {
    return BBPlatformClient.isBBWorldMode();
  }

  // AP: can't be named "useAndroidJSInterface" like the param because React (or eslint?) will think it's a hook
  static isAndroidJSInterface(): boolean {
    return BBPlatformClient.isAndroidJSInterface();
  }

  static async apiRequest(
    method: "get" | "post" | "put",
    props: RequestProps
  ): Promise<any> {
    const {
      url,
      headers: providedHeaders = {
        "Content-Type": "application/json",
        "webgenai-client-version": true,
      },
      body: bodyJSON,
      publicEndpoint = false,
      isAuthRetry = false,
    } = props;
    let headers = providedHeaders;
    if (!publicEndpoint) {
      const tokens = BBPlatformClient.getUnexpiredTokens();
      if (tokens !== null) {
        headers = { ...headers, Token: tokens.AccessToken};
      } else {
        throw new BBPlatformClientNotAuthorizedError();
      }
    }
    const body = bodyJSON !== undefined ? JSON.stringify(bodyJSON) : undefined;

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    const responseBody = await response.json();
    // TODO - handle network or parse errors via catch?

    if (response.status >= 200 && response.status < 300) {
      return responseBody;
    } else if (response.status === 403 || response.status === 401) {
      BBAIPlatformClient.tokens = null;
      if (!isAuthRetry) {
        // try again - nulling out the accessToken should trigger a token refresh if possible
        // make sure isAuthRetry is true so we don't end up in an endless loop
        return await BBAIPlatformClient.apiRequest(method, {
          ...props,
          isAuthRetry: true,
        });
      } else {
        console.error(
          `[BBAIPlatformClient] not authorized: ${url} ${response.status} ${responseBody.metadata}`
        );
        throw new BBPlatformClientNotAuthorizedError();
      }
    } else if (response.status >= 400 && response.status < 500) {
      throw new BBPlatform4XXError(url, response.status, responseBody);
    } else if (response.status >= 500 && response.status < 600) {
      throw new BBPlatformServerError(
        url,
        response.status,
        responseBody
      );
    } else {
      const message = `Received unexpected status code from ${method} ${url}: ${response.status}`;
      throw new BBPlatformClientError(message);
    }
  }

  static async apiGet(props: RequestProps): Promise<any> {
    return (await BBAIPlatformClient.apiRequest("get", props));
  }

  static async apiPost(props: RequestProps): Promise<any> {
    return (await BBAIPlatformClient.apiRequest("post", props));
  }

  static async apiPut(props: RequestProps): Promise<any> {
    return (await BBAIPlatformClient.apiRequest("put", props));
  }


  /* ***************************************************************************
   * endpoint methods below
   ************************************************************************** */

  // TODO: codify the format of the response, maybe? it's pretty complicated and still changing a lot...
  static async cyoaGameAsJson(userInput: string, numberOfChapters: number = 10): Promise<any> {
    // console.log("api send cyoa game request with user prompt: ", userInput)
    return await BBAIPlatformClient.apiPost({
      url: `${Env.BBAI_BASE_URL}/cyoa/game_as_json`,
      body: {
        "openai_configs": {
          "model_name": Env.CYOA_OPENAI_MODEL,
          "temperature": Env.CYOA_OPENAI_TEMPERATURE,
        },
        "user_input": userInput,
        "number_of_chapters": numberOfChapters
      },
    });
  }

  static async testError(): Promise<any> {
    throw new BBPlatformClientError("test error");
  }

}
