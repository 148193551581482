import { BBPlatformClient } from "../api/BBPlatform";
import { PostSSOTokensDTO } from "../constants/GenPrompTypes";
import jwt, { JwtPayload }  from 'jwt-decode'
import Env from "../Env";


export const generateTokenForSSO = async (): Promise<string> => {

  const tokens = BBPlatformClient.getUnexpiredTokens()

  if(!tokens){
    console.debug("No tokens founds")
    return ""
  }

  const decodedToken = jwt<JwtPayload>(tokens.AccessToken)

  if(decodedToken.exp === undefined || decodedToken.exp > (new Date().getTime())){
    console.debug("Access token expired, generating new token")
    return ""
  }

  const reqBody:PostSSOTokensDTO = {
    clientId: Env.APP_CLIENT_ID,
    clientSecret: "",
    refreshToken: tokens.RefreshToken,
    accessToken: tokens.AccessToken,
  }
  
  const token = await BBPlatformClient.getSSOToken(reqBody);
  
  return token
}
