import styles from "./App.module.css";
import { ConfigProvider } from "antd";
import { AuthWrapper } from "./components/AuthWrapper";
import { useEffect, useState } from "react";
import { BBPlatformClient, TimestampedTokens } from "./api/BBPlatform";
import useNewCYOAStore from "./newCYOAStore";
import GenerAiPrompt from "./pages/GenAIPrompt/GenAIPrompt";
import useUserStore from "./userStore";
import TagManager from 'react-gtm-module';
import Env from "./Env";
import { CloseOutlined } from "@ant-design/icons";

function App() {
  const [authTokens, setAuthTokens] = useState<TimestampedTokens | null>(null);
  const [guestMode, setGuestMode] = useState<boolean>(false);
  const newCyoaStore = useNewCYOAStore();
  const userStore = useUserStore();
  const [logoutMode, setLogoutMode] = useState<boolean>(false);

  function handleTokenChange(newTokens: TimestampedTokens | null): void {
    // initialNewCYOAState.isGuest = newTokens?.isGuest ?? true;
    newCyoaStore.setGuest(newTokens?.isGuest ?? true);
    getUserData(newTokens);
    setAuthTokens(newTokens);
    // const aToken = newTokens?.AccessToken ?? 'null';
    // const rToken = newTokens?.RefreshToken ?? 'null';
    // localStorage.setItem("accessToken", aToken);
    // localStorage.setItem("refreshToken", rToken);
  }

  function handleClaimRequest(): void {
    setGuestMode(false);
    setAuthTokens(null);
    newCyoaStore.setShowAuthFrame(true);
  }

  const [timestamp, setTimestamp] = useState<number>(0);

  const handleNullifyTokens = () => {
    setTimestamp(Date.now());
    window.location.reload();
  };

  const logout = () => {
    // initialBBAIState.isGuest = true;  
    newCyoaStore.initial()
    userStore.initial()
    setGuestMode(true);
    setAuthTokens(null);
    setLogoutMode(true);
    newCyoaStore.setShowAuthFrame(true);
    // newCyoaStore.initial()
    // userStore.initial()
  }

  const getUserData = async (newTokens: TimestampedTokens | null ) => { 
    if(newTokens === null ||( newTokens?.isGuest?? true)) {
      return
    }
    const res = await BBPlatformClient.getUserData()
    // console.log(res)
    userStore.setUserId(res.user.Id)
  }

  useEffect(() => {
    // InitializeGoogleAnalytics()
    TagManager.initialize({ gtmId: Env.GTM_ID });

    if(userStore.baseUserInfo?.email){
       handleClaimRequest()
    }
    // eslint-disable-next-line
  }, [])

  const handleCloseLogin = () => {
    newCyoaStore.setShowAuthFrame(false);
  }



  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F06422",
          colorTextDisabled: "#505050",
        },
        components: {
          Input: {
            colorBgContainer: "#505050",
            colorText: "#e0e0e0",
            colorTextPlaceholder: "#e0e0e0",
            // fontSize: 16,
            // padding: 20,
            colorBgContainerDisabled: "#505050"
          },
          Card: {
            borderRadius: 2,
            borderRadiusLG: 2,
            colorBorder: "#101010",
            colorBorderSecondary: "#101010",
            colorBgContainer: "#101010",
            colorText: "#AAA",
          },
          Progress: {
            colorText: "#e0e0e0",
          },
        },
      }}
    >
      <div className={styles.App}>
        {newCyoaStore.showAuthFrame && <CloseOutlined onClick={handleCloseLogin} className={styles.closeIcon} />}
        <AuthWrapper
          guestAccountMode={guestMode}
          createAccountMode={newCyoaStore.createAccountMode}
          tokens={authTokens}
          onTokenChange={handleTokenChange}
          nullifyTokens={timestamp}
          logoutMode={logoutMode}
        >
          {/* <PlaceholderFormComponent
            unauthorizedErrorHandler={handleNullifyTokens}
            onClaimRequest={handleClaimRequest}
            propagateLogout={logout}
            store={cyoaStore}
          /> */}
          <GenerAiPrompt  
            store={newCyoaStore}
            unauthorizedErrorHandler={handleNullifyTokens}
            onClaimRequest={handleClaimRequest}
            propagateLogout={logout}/>

        </AuthWrapper>
      </div>
    </ConfigProvider>
  );
}

export default App;
