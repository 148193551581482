import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { BasicUserInfo } from './constants/BBPlatform.types';
import { BBAI_STORE_VERSION, storeStorageVersion } from "./utils/StorageVersion";

const BBAI_STORAGE_KEY =  "user-store";

storeStorageVersion(BBAI_STORE_VERSION, BBAI_STORAGE_KEY);

export type UserState = {
  userId: string
  version: number
  baseUserInfo: BasicUserInfo | null
};

export type UserAction = {
  setUserId: (userId: string) => void;
  setUserBasicInfo: (userInfo: BasicUserInfo ) => void;
  initial: () => void;
};

export const initialUserState: UserState = {
  userId: "",
  version: BBAI_STORE_VERSION,
  baseUserInfo: null
}; 

//TODO: too many repeated function, refactor
const useUserStore = create<UserState & UserAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialUserState,
        setUserId: (userId: string) => {
          set((state) => ({ 
            ...state,
            userId 
          }));
        },
        setUserBasicInfo: (userInfo: BasicUserInfo ) => {
          set((state) => ({
            ...state,
            baseUserInfo: userInfo
          }))
        },
        initial: () => {
          set((state) => ({
            ...state,
            ...initialUserState,
          }));
      }}),
      {
        name: "user-store", // unique name
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);



export default useUserStore;
