import { useEffect, useRef, useState } from 'react'
import { BBPlatformClient } from '../../../api/BBPlatform'
import { bbaiCYOAPromptOptions, bbaiCYOAPromptStepStatus, bbaiUser } from '../../../constants/bbai.prompts'
import useNewCYOAStore from '../../../newCYOAStore'
import PromptMessage from './PromptMessage'
import { v4 as uuidv4 } from "uuid";
import useUserStore from '../../../userStore'
import { customEventDataLayerTrigger } from '../../../api/GoogleTagManager'

export const GeneratingGameAssetsCYOA = () => {

  const bbaiStore = useNewCYOAStore()
  const userStore = useUserStore()
  // const userStore = useUserStore()
  const genAssetsRan = useRef(false) // make sure the useFfect fetch call only 
  const startTimer = useRef(false)
  const [generatedDataSuccess, setGeneratedDataSucesss] = useState<boolean>(false)

  useEffect(() => {
    if (bbaiStore.generatedCYOAGameData === null) {
      // still waiting on game data
      return;
    }
    if(!genAssetsRan.current && !bbaiStore.completed) {
      startTimer.current = true
      generateCYOAGameAssets();

    }

    if(bbaiStore.completed){
      bbaiStore.nextStage()
    }
    return () => {
      // allow the items to run once here
      genAssetsRan.current = true
    }
    // eslint-disable-next-line
  }, [ bbaiStore.generatedCYOAGameData ]);  

  const generateCYOAGameAssets = async() => {
    // TODO: remove debug
    let skipGenerate = false
    try {
    const userCreditsInfo = await BBPlatformClient.getBalance()

      if (userCreditsInfo.balance < 1) {
        skipGenerate = true;

        // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
      }

    if (bbaiStore.generatedCYOAGameData === null) { 
      console.error("ERROR: no game data to generate assets from")
    }

    if(skipGenerate) {
      console.log("skipping generating CYOA game assets")
    }
    else {
      console.log("generating CYOA game assets")

      const newProjectID = await BBPlatformClient.cyoaNewId()
      bbaiStore.setProjectId(newProjectID)
      //TODO: remove the project id from the known state.

      // things to do...
      let jobRequest = {
        prompt: "",
        costInVC: 0,
        disableSuggestedOptimizations: true,
        jobId: uuidv4(),
        width: 512,
        height: 896,
        removeBackground: false,
        seed: Math.floor(Math.random() * 1000000),
        webCYOASaveCopyToS3Filename: "",
        webCYOAProjectId: newProjectID,
        projectId: newProjectID,
      }

      let promisesImages = []

      // create title screen image
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "title.png"    
      jobRequest.prompt = bbaiStore.generatedCYOAGameData.title.imageDescription
      promisesImages.push(BBPlatformClient.generateClipdropAsset(jobRequest))

      // create win screen image
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "win.png"    
      jobRequest.prompt = bbaiStore.generatedCYOAGameData.win.imageDescription
      promisesImages.push(BBPlatformClient.generateClipdropAsset(jobRequest))

      // create lose screen image
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "lose.png"    
      jobRequest.prompt = bbaiStore.generatedCYOAGameData.lose.imageDescription
      promisesImages.push(BBPlatformClient.generateClipdropAsset(jobRequest))

      // create all chapter images
      let chapterInfo : [] = bbaiStore.generatedCYOAGameData.chapters
      for(let ii = 0; ii < chapterInfo.length; ii++) {
        const chapter : any = chapterInfo[ii]
        jobRequest.jobId = uuidv4()
        jobRequest.webCYOASaveCopyToS3Filename = `chapter${ii}.png`    
        jobRequest.prompt = chapter.imageDescription
        promisesImages.push(BBPlatformClient.generateClipdropAsset(jobRequest))
      }

      // create icon? NYFI
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "icon.png"
      jobRequest.width = 512
      jobRequest.height = 512
      jobRequest.prompt = "Make an app icon for a game: " + bbaiStore.generatedCYOAGameData.title.title + " " + bbaiStore.generatedCYOAGameData.title.mission //bbaiStore.generatedCYOAGameData.iconImageDescription
      promisesImages.push(BBPlatformClient.generateClipdropAsset(jobRequest))   

      // TODO: get music?
      // for now let's hardcode it since I'm missing a prompt to send to our system to get a url
      // until then, we'll just put a url manually
      let musicUrl = "https://frontend-assets.dev.8cell.com/bg.mp3"
      await BBPlatformClient.emplaceCYOAResource(newProjectID, "background_music.mp3", musicUrl)

      const values = await Promise.all(promisesImages)
      
        customEventDataLayerTrigger("Web GenAI Images Complete", userStore.userId)

        await BBPlatformClient.emplaceCYOAResource(newProjectID, "background_music.mp3", musicUrl)
        customEventDataLayerTrigger("Web GenAI Sound Complete", userStore.userId)


        console.log("asset generation complete!", values)
        // await BBPlatformClient.cyoaConfirmComplete(newProjectID)
        
        setGeneratedDataSucesss(true)
        bbaiStore.setAllAssetsGenerated(true)
        // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.BUILD_BBDOC);
        
        bbaiStore.nextStage()
    }
    } catch (err) {
      console.error("ERROR: while generating game assets: ", err)
      skipGenerate = true
        bbaiStore.setErrorState({
          title: "Error generating Story Game assets",
          message: bbaiCYOAPromptOptions[bbaiStore.promptStepStatus]?.errPrompt,
        });     
        bbaiStore.setGeneratedCYOAGameData(null)
        // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  }
   return (
    <>
      {<PromptMessage promptStage={bbaiCYOAPromptStepStatus.GENERATING_GAME_ASSETS} message={bbaiCYOAPromptOptions.GENERATING_GAME_ASSETS.prompt} user={bbaiUser}
      loading={startTimer.current} successReport={generatedDataSuccess}
      /> }
    </>
  )
}
