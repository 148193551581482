import { Button } from "antd";
import { ReactElement, useMemo } from "react";
import classnames from "classnames";
import Env from "../Env";
import useIsMobile from "../hooks/useIsMobile";

import styles from "../styles/PlaceholderFormComponent.module.css";
import { BBPlatformClient } from "../api/BBPlatform";
import { generateTokenForSSO } from "../utils/CognitoHelper";
// import DropDownMenu from "./DropDownMenu";

export function NavButtons(): ReactElement {
  const isBBWorldMode = useMemo(() => BBPlatformClient.isBBWorldMode(), []);
  const isMobile = useIsMobile();

  const navigateTo = (url: string) => {
    window.location.replace(url);
  };

  const setTokenQuery = async (baseUrl: string) => {

    console.log("WE are here lets goo")
    const token = await generateTokenForSSO()
    
    const urltoken = token === ""? baseUrl : `${baseUrl}?token=${token}`

    console.debug("setTokenQuery", urltoken)
    navigateTo(urltoken);
  }

  return (
    <div
      className={classnames({
        [styles.buttonContainer]: !isMobile,
        [styles.buttonContainerMobile]: isMobile,
      })}
    >
      {
        // isMobile && !isBBWorldMode ? // mobile
        //   <DropDownMenu/>
        //   : undefined

        isMobile && !isBBWorldMode ? ( // mobile
          <>
            <Button
              onClick={() => navigateTo(Env.LEARN_MORE_URL as string)}
              className={styles.generalBtnSpacing}
              type="primary"
            >
              Learn More
            </Button>
            <Button
              onClick={() => setTokenQuery(Env.VIEW_PLANS_URL as string)}
              className={styles.generalBtnSpacing}
              type="primary"
            >
              View Plans
            </Button>
            <Button
              onClick={() => setTokenQuery(Env.MY_ACCOUNT_URL as string)}
              className={styles.defaultBtn}
            >
              My Account
            </Button>
          </>
        ) : undefined
      }
      {!isMobile && !isBBWorldMode ? ( // desktop
        <>
          <a target="_blank" rel="noreferrer" href={Env.LEARN_MORE_URL}>
            <Button className={styles.generalBtnSpacing} type="primary">
              Learn More
            </Button>
          </a>
          <Button className={styles.generalBtnSpacing} onClick={() => setTokenQuery(Env.VIEW_PLANS_URL as string)} type="primary">
            View Plans
          </Button>

          <Button className={styles.defaultBtn} onClick={() => setTokenQuery(Env.MY_ACCOUNT_URL as string)}>My Account</Button>
        </>
      ) : undefined}
    </div>
  );
}
