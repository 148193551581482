import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "../../../styles/PromptMessage.module.css";
import { Row, Col } from "antd";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  AssetType,
  bbaiCYOAPromptStepStatus,
} from "../../../constants/bbai.prompts";
import CircularProgress from "../../../components/CircularProgress";
import useInfiniteProgress from "../../../hooks/useInfiniteProgress";

export interface MessageProps {
  message: string;
  user: {
    id: string;
    pic: string;
  };
  // handleSelectedAsset?: (img: string, assetType: string, idx: number) => void;
  assetType?: AssetType | bbaiCYOAPromptStepStatus;
  promptStage:  bbaiCYOAPromptStepStatus;
  handleDoneTyping?: () => void;
  loading?: boolean | null;
  successReport?: boolean
  component?: JSX.Element;
}

const PromptMessage: FunctionComponent<MessageProps> = ({
  message = "",
  user,
  promptStage,
  loading = false,
  successReport = false,
  component,
  handleDoneTyping
}) => {
  const [messageText, setMessageText] = useState<string>("");
  const indexRef = useRef(0);
  const intervalRef = useRef<any>();
  // const [progressValue, setProgressValue] = useState<number>(0)
  const progressValue = useInfiniteProgress({successReport})

  const updateMessageText = () => {
    const currentText = message[indexRef.current]
      ? message[indexRef.current]
      : "";

    setMessageText((prev) => prev + currentText);
  };

  useEffect(() => {
    indexRef.current = 0;

    if (
      user.id === "1" &&
      indexRef.current === 0
    ) {
      intervalRef.current = setInterval(() => {
        updateMessageText();

        if (indexRef.current >= message.length) {
          clearInterval(intervalRef.current);
          indexRef.current = 0;
          // wait a tick, then fire the event
          requestAnimationFrame(() => handleDoneTyping?.())
        }
      }, 50); // adjust speed here

      return () => {
        clearInterval(intervalRef.current);
        indexRef.current = 0;
        setMessageText("");
      }; // cleanup on unmount
      // setMessageText(message);
    } else {
      setMessageText(message);
    }
    // eslint-disable-next-line
  }, [message, user]);

  useEffect(() => {
    if (
      user.id === "1" &&
      ((messageText.length === 0 && indexRef.current === 1) ||
        (messageText.length === 0 && indexRef.current === 0))
    ) {
      indexRef.current = 0;
      setMessageText("");
    } else {
      indexRef.current += 1;
    }
    // eslint-disable-next-line
  }, [messageText, user]);


  const checkDoneTypingShowLoad = () => {
  return loading && (message === messageText)
  }

  return (
    <>
      <Row
        className={`${styles.promptMessage} ${
          user.id === "1"
            ? styles.promptFeedbackBBAI
            : styles.promptFeedbackUser
        }`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={user.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
          { component? component :  <> { messageText} {""} {checkDoneTypingShowLoad()  &&  <CircularProgress progress={progressValue} />} </>
          }
        </Col>
      </Row>
    </>
  );
};

export default PromptMessage;
