import { FunctionComponent, useEffect, useState } from "react";
import styles from "../styles/LoginStatus.module.css";
import { Space, Badge, Button } from "antd";
// import { bbaiCYOAPromptStepStatus } from "../constants/bbai.prompts";
import useIsMobile from "../hooks/useIsMobile";
// import { BBPlatformClient, BBPlatformClientNotAuthorizedError } from "../api/BBPlatform";
import useNewCYOAStore from "../newCYOAStore";
import useUserStore from "../userStore";
import { BasicUserInfo } from "../constants/BBPlatform.types";

// const LOGIN_DISABLED_STATUSES_CYOA = [bbaiCYOAPromptStepStatus.GENERATING_GAME_DATA, bbaiCYOAPromptStepStatus.GENERATING_GAME_ASSETS, bbaiCYOAPromptStepStatus.BUILD_BBDOC]

interface LoginStatusProps {
    onLogin: () => void
    propagateLogout?: () => void
}

const LoginStatus: FunctionComponent<LoginStatusProps> = ({onLogin,propagateLogout}) => {
    const isMobile = useIsMobile();
    const [userName, setUserName] = useState<string>("");
    const cyoaStore = useNewCYOAStore()
    const userStore = useUserStore()
    // const disabledCYOA = LOGIN_DISABLED_STATUSES_CYOA.indexOf(cyoaStore.promptStepStatus) >= 0
    // const isDisabled = disabledGenAI || disabledCYOA
    // const isDisabled = disabledCYOA
    // const tooltipText = disabledCYOA ? "Account log-in is disabled while you are in the middle of generating your game. You'll be able to claim your game to an existing Buildbox account when this is done." : undefined

    const handleLoginStatus = () => {
        if (!cyoaStore.isAuthed) {
            // set prompt status to login
            // bbaiStore.setPromptStepState(bbaiPromptStepStatus.LOGIN_ACCOUNT);
            onLogin()
        } else {
            propagateLogout?.();
        }
    }

    useEffect(() => {
       
        if(userStore.baseUserInfo){
            setUserDetails(userStore.baseUserInfo);
        }
    }, [userStore.baseUserInfo, userStore.baseUserInfo?.email]);

    useEffect(() => {
        if(userStore.baseUserInfo){
            setUserDetails(userStore.baseUserInfo);
        }
        // eslint-disable-next-line
    }, [])

     const setUserDetails = (baseUserInfo: BasicUserInfo) => {
        const {cognitoUserId, email} = baseUserInfo
        let newUserName = cognitoUserId
        if (cognitoUserId.indexOf("Google_") === 0) {
            newUserName = email
        }
        setUserName(newUserName);
            
    }
    return (
        <>
            <Space size="large">
                {userStore.baseUserInfo && <Badge status="success" dot={true}>
                    <p className={styles.badgeText}>[{userName}]</p>
                </Badge>}
            </Space><br />
            {/* <Tooltip title={tooltipText} > */}
            <div style={{display: "flex", flexDirection: "row-reverse"}}>
                <Button
                    className={styles.loginButton}
                    onClick={() => handleLoginStatus()}
                    size="small"
                    type="primary"
                    ghost={isMobile ? false : true}
                    disabled={false}
                    // disabled={bbaiStore.promptStepStatus !== bbaiPromptStepStatus.INITIAL && bbaiStore.isGuest && bbaiStore.promptStepStatus !== bbaiPromptStepStatus.BUILD_REGERNATE_RESTART && bbaiStore.promptStepStatus !== bbaiPromptStepStatus.CLAIM_LOGIN_SKIP}
                >
                    {!userStore.baseUserInfo ? "Login" : "Logout"}
                </Button>
            </div>
            {/* </Tooltip> */}
        </>
    );
}

export default LoginStatus;