import { useEffect, useRef, useState } from 'react'
import { bbaiCYOAPromptOptions, bbaiCYOAPromptStepStatus, bbaiUser } from '../../../constants/bbai.prompts'
import PromptMessage from './PromptMessage'
import useNewCYOAStore from '../../../newCYOAStore'
import { BBAIPlatformClient } from '../../../api/BBAIPlatform'
import { customEventDataLayerTrigger } from '../../../api/GoogleTagManager'
import useUserStore from '../../../userStore'

const GeneratingGameDataCYOA = () => {

  const bbaiStore = useNewCYOAStore()
  const userStore = useUserStore()
  const generateDataCYOA = useRef<boolean>(false) // make sure the useFfect fetch call only runs once
  const startTimer = useRef(false)
  const [generatedDataSuccess, setGeneratedDataSucesss] = useState<boolean>(false)

  useEffect(() => { 
    let interval: any;
    /// TODO: generate the game data here, then next step
    
    if(bbaiStore.userStoryPrompt.length > 0 && generateDataCYOA.current === false && !bbaiStore.completed){
      const bodyGenAI = {
        UserID: userStore.userId,
        genai_question: bbaiCYOAPromptOptions["INITIAL"].prompt,
        genai_user_response: bbaiStore.userStoryPrompt,
        genai_skipped: false,
      }
      customEventDataLayerTrigger("Web GenAI Prompt", userStore.userId, bodyGenAI)
      startTimer.current = true
      generateCYOAGameData(bbaiStore.userStoryPrompt)
      
    }

    if(bbaiStore.completed){
      console.log("is it completed? ", bbaiStore.completed)
      bbaiStore.nextStage()
    }

    return () => {
      generateDataCYOA.current = true
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [])

  const generateCYOAGameData = async(
    userPrompt: string,
  ) => {
      
    bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.GENERATING_GAME_DATA)
    const numberOfChapters = 10
    // even this request can take awhile, I'm nervous to await it... ?
    // console.log("request cyoa with user input: ", userPrompt, " and ", numberOfChapters, " chapters")
    // TODO: catch and handle error?

    try{
      const genJob = await BBAIPlatformClient.cyoaGameAsJson(userPrompt, numberOfChapters)
      bbaiStore.setGeneratedCYOAGameData(genJob)
      
      customEventDataLayerTrigger("Web GenAI Generate", userStore.userId)
      
      setGeneratedDataSucesss(true)
      bbaiStore.nextStage()  
      // AP: move on to generate assets automatically? how?
      console.log("got game data; TODO: generate assets from it")  
    } catch(err) {
      console.error("ERROR: ", err)
      bbaiStore.setErrorState({
        title: "Error Generating Story Game Data",
        message: "There was an error generating your Story game data. Please try again later.",
      });
      startTimer.current = false
      // unauthorizedErrorHandler?.();
      // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  }
  return (
    <>
     <PromptMessage promptStage={bbaiCYOAPromptStepStatus.GENERATING_GAME_DATA} message={bbaiCYOAPromptOptions.GENERATING_GAME_DATA.prompt} user={bbaiUser}
      loading={startTimer.current} successReport={generatedDataSuccess}
      />
    </>
  )
}

export default GeneratingGameDataCYOA