import { useEffect, useRef } from 'react'
import { BBPlatformClient } from '../../../api/BBPlatform'
import { handlePromptStepAnalytics } from '../../../api/GoogleAnalyticsWrapper'
import { customEventDataLayerTrigger } from '../../../api/GoogleTagManager'
import { bbaiCYOAPromptOptions, bbaiCYOAPromptStepStatus, bbaiUser } from '../../../constants/bbai.prompts'
import useNewCYOAStore from '../../../newCYOAStore'
import useUserStore from '../../../userStore'
import ClaimLoginSkipOptions, { ClaimLoginSkipOptionsType } from './ClaimLoginSkipOptions'
import PromptMessage from './PromptMessage'

const BuildBBDocCYOA = () => {
  const bbaiStore = useNewCYOAStore()
  const userStore = useUserStore()
  ///TODO: make sure the the bbdoc is ready to download 
  const generateBBDoc = useRef<boolean>(false) // make sure the useFfect fetch call only runs once

  useEffect(() => {

    if(generateBBDoc.current === false && !bbaiStore.isGuest && bbaiStore.allAssetsGenerated && !bbaiStore.completed){
      generateBBDOC();
      generateBBDoc.current = true;
    }

    if(bbaiStore.completed){
      bbaiStore.nextStage()
    }

    // if(generateBBDoc.current === false){
    //   generateBBDOC();
    //   generateBBDoc.current = true;
    // }
    // eslint-disable-next-line
  }, [] )


  const handleClaimLoginSkip = (e: ClaimLoginSkipOptionsType) => {
    if (e === ClaimLoginSkipOptionsType.CLAIM) {
      console.debug(`[handleClaimLoginSkip] CLAIM`);
      handlePromptStepAnalytics(bbaiCYOAPromptStepStatus.CLAIM_ACCOUNT);
      // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.CLAIM_ACCOUNT);
    } else if (e === ClaimLoginSkipOptionsType.LOGIN) {
      console.debug(`[handleClaimLoginSkip] LOGIN`);
      handlePromptStepAnalytics(bbaiCYOAPromptStepStatus.LOGIN_ACCOUNT);
      // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.LOGIN_ACCOUNT);
    } else if(e === ClaimLoginSkipOptionsType.SKIP) {
      console.debug(`[handleClaimLoginSkip] DOWNLOAD aka SKIP`);
      handlePromptStepAnalytics(bbaiCYOAPromptStepStatus.DOWNLOAD_BBDOC);
      // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.DOWNLOAD_BBDOC);
    }
    else {
      console.error("ERROR: unexpected state for claim/login/skip click")
    }
  };

  const generateBBDOC = async (): Promise<void> => {
    try{
        console.log("CYOA ask platform to generate bbdoc...", "Game Data:", bbaiStore.generatedCYOAGameData )
        const res = await BBPlatformClient.createCYOABBDoc(bbaiStore.projectId, bbaiStore.generatedCYOAGameData);

        customEventDataLayerTrigger("Web GenAI BBDoc Complete ", userStore.userId)

        if (res.projectBBDocUrl === undefined) {
          console.error("BBDOC URL is undefined");
          throw new Error("BBDOC URL is undefined");
        }

        console.debug("generated bbdoc download link: ", res.projectBBDocUrl);
        console.log("generated bbdoc id: "+ res.bbdocId)
        bbaiStore.setBBWorldBBDocId(res.bbdocId);

        await BBPlatformClient.cyoaConfirmComplete(bbaiStore.projectId)
        customEventDataLayerTrigger("Web GenAI Complete", userStore.userId)
        bbaiStore.setConfirmComplete()
        bbaiStore.nextStage()
        // setBBDoc(res.projectBBDocUrl);
    } catch(error){
      // TODO: handle error!!! I even added this console message, it was just empty here!
      console.error("Unexpected error while generating bbdoc: ", error)
      // throw error
      bbaiStore.setErrorState({
        title: "Error generating Story Game download",
        message: bbaiCYOAPromptOptions[bbaiStore.promptStepStatus]?.errPrompt,
      });
      // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  }

  
  return (
    <>
      {<PromptMessage promptStage={bbaiCYOAPromptStepStatus.BUILD_BBDOC} message={bbaiCYOAPromptOptions.BUILD_BBDOC.prompt} user={bbaiUser}
      /> }
      { bbaiStore.isGuest && <ClaimLoginSkipOptions handleClick={handleClaimLoginSkip}/>}
    </>
  )
}

export default BuildBBDocCYOA