import { ReactElement, ReactNode, useEffect } from "react";

import { BBAuthFrame, AuthResult } from "bb-auth-frame/component";
import { BBPlatformClient, BBPlatformClientNotAuthorizedError, TimestampedTokens } from "../api/BBPlatform";
import { EnvironmentModes } from "bb-auth-frame/bb-post-message";
import Env from "../Env";
import { sendCustomEvent } from "../api/GoogleAnalyticsWrapper";
import useNewCYOAStore, {initialNewCYOAState} from "../newCYOAStore";
import useUserStore from "../userStore";

const containerStyles = {
  maxWidth: "680px",
  height: "100%",
  maxHeight: "800px",
  margin: "auto",
};

type Props = {
  guestAccountMode: boolean;
  createAccountMode: boolean;
  tokens: TimestampedTokens | null;
  onTokenChange: (newTokens: TimestampedTokens | null) => void;
  children?: ReactNode | ReactNode[];
  nullifyTokens: number;
  logoutMode?: boolean;
};

export function renderChildren(
  isAuthed: boolean,
  children: ReactNode | ReactNode[] | undefined
): ReactElement | undefined {
  if (isAuthed) {
    return <>{children}</>;
  }
}

export function AuthWrapper({
  tokens,
  guestAccountMode,
  createAccountMode,
  children,
  onTokenChange,
  logoutMode,
}: Props): ReactElement {

  const cyoaStore = useNewCYOAStore();
  const userStore = useUserStore();

  function handleAuthSuccess(tokens: AuthResult) {
    console.debug(`[AuthWrapper].handleAuthSuccess]`);
    const timestamped: TimestampedTokens = { ...tokens, timestamp: Date.now() };
    sendCustomEvent("account_login");
    // TODO CT: handle account_create when able to detect
    BBPlatformClient.setTokens(timestamped);
    onTokenChange(timestamped);

    initialNewCYOAState.isGuest = tokens.isGuest ?? true;
    cyoaStore.setGuest(tokens.isGuest);

    if(tokens.isGuest === false){
      localStorage.setItem("isGuest", "false");
      getBasicUserInfo();
    } else {
      localStorage.setItem("isGuest", "true");
    }
    cyoaStore.setShowAuthFrame(false);
  }

  function handleAuthFail(message: string) {
    console.error(`[AuthWrapper] auth failure: ${message}`);
    BBPlatformClient.setTokens(null);
    onTokenChange(null);
  }

  async function getBasicUserInfo() {
    try{
      const userInfo = await BBPlatformClient.getUserInfo()
      userStore.setUserBasicInfo(userInfo.basicUserInfo)
      userStore.setUserId(userInfo.userId)

    } catch(e) {
        if (e instanceof BBPlatformClientNotAuthorizedError) {
          console.error(`[LoginStatus].useEffect] got not authorized error`)
          handleAuthFail(e.message) // failed need to attempt to login again.
        }
        else {
          throw e
        }
    
      console.error(e)
    }
  }

  useEffect(() => {
    const isAuthed = tokens !== null || BBPlatformClient.isBBWorldMode();
    cyoaStore.setIsAuthed(isAuthed);

    if(BBPlatformClient.isBBWorldMode()){
      cyoaStore.setGuest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  return (
    <>
      {renderChildren(!cyoaStore.showAuthFrame, children)}
      {!BBPlatformClient.isBBWorldMode() &&  cyoaStore.showAuthFrame ? (
        <BBAuthFrame
          containerStyles={containerStyles}
          guestAccountMode={guestAccountMode}
          startInCreateMode={createAccountMode}
          compact // this turns off the "Create Account" form
          key={"authframe"}
          tokens={tokens}
          onAuthSuccess={handleAuthSuccess}
          onAuthFail={handleAuthFail}
          environment={Env.AOB_ENV as EnvironmentModes}
          logoutMode={logoutMode}
        />
      ) : undefined}
    </>
  );
}
