import { FunctionComponent } from "react";
import useNewCYOAStore from "../../../newCYOAStore";
import {AuthOption} from "../../../constants/GenPrompTypes";

interface LoginPromptProps {

}

const LoginPrompt: FunctionComponent<LoginPromptProps> = () => {
    const newCyoaStore = useNewCYOAStore();
    const handleLogin = (authOption: AuthOption) => {
        if (!newCyoaStore.isAuthed) {
            // set prompt status to login
            // bbaiStore.setPromptStepState(bbaiPromptStepStatus.LOGIN_ACCOUNT);
            newCyoaStore.setCreateAccountMode(!!authOption);
            newCyoaStore.setShowAuthFrame(true);
        }
    }
    return (
        <>
            <p>
                To continue, <a onClick={() => handleLogin(AuthOption.SIGNUP)} href="#/">Create an Account</a> or <a onClick={() => handleLogin(AuthOption.LOGIN)} href="#/">Log-In</a>
            </p>
        </>
    );
}

export default LoginPrompt;