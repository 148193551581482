import {useEffect, useState} from 'react'
import { BBPlatformClient } from '../../../api/BBPlatform';
// import DownloadOptions from './DownloadOptions';
import useIsMobile  from '../../../hooks/useIsMobile';
import useNewCYOAStore from '../../../newCYOAStore';
import { Col, Row } from 'antd';
import styles from "../../../styles/PromptMessage.module.css";
import { bbaiCYOAPromptOptions, bbaiUser } from '../../../constants/bbai.prompts';
import Env from '../../../Env';
import AnimatedButton from '../../../components/AnimatedButton';
import downloadStyles from "../../../styles/DownloadLink.module.css";

enum FileType {
    BBCLASSIC_INSTALLER = "bbclassic_installer",
    BBDOC = "bbdoc",
}

export const DownloadBBDocCYOA = () => {
  const [bbdoc, setBBDoc] = useState<string | null>(null)
  // const [urlExpireTime, setUrlExpireTime] = useState<number>(0)
  const isMobile = useIsMobile()
  const bbaiStore = useNewCYOAStore()

  useEffect(() => {
    getBBDOC()
    // eslint-disable-next-line
  }, [])

  const downloadBBDOC = async () => {
    // console.log("testing date logic", urlExpireTime < (new Date().getTime() ))
    if (!bbdoc) {
      console.log("bbdoc no longer exists, regenerating", bbdoc)
      // unauthorizedErrorHandler?.();
      await getBBDOC();
      return;

    }
    const a = document.createElement("a");
    a.download = "StoryGame.bbdoc";
    a.href = bbdoc;
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const getBBDOC = async (): Promise<void> => {
    try {
      if( bbaiStore.bbworldBBDocId !== ""){
        const res = await BBPlatformClient.getCYOABBDocDownloadLink(bbaiStore.projectId)
        setBBDoc(res.url)
        bbaiStore.setBBDocUrl(res.url)
      } else {
        console.error("error: bbdoc id is empty")
        // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
      }
    } catch (error) {
      console.error("Error: ", error);
      // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  };

  const handleClickDownloadBBDOC = async (fileType: FileType) => {
    // console.log("e", fileType);

    try{

    if (isMobile) {

      // const deepLink = await BBPlatformClient.getFirebaseShortenDeepLink(bbaiStore.bbworldBBDocId)
      // console.debug("firebase shorten deepLink: ", deepLink)
      // window.location.replace(deepLink)
      BBPlatformClient.openBBWorld(bbaiStore.bbworldBBDocId)
      
    } else if (fileType === FileType.BBDOC) {
      downloadBBDOC();
    } else if (fileType === FileType.BBCLASSIC_INSTALLER) {
      await BBPlatformClient.downloadBuildboxClient(null, "BB2");
    }
    else {
      console.error("error: unexpected state for download click")
    }
  } catch(error){
    console.error("error: ", error)
    // TODO: handle error
    bbaiStore.setErrorState({
      title: "Error generating Story Game download",
      message: bbaiCYOAPromptOptions[bbaiStore.promptStepStatus]?.errPrompt,
    });
    // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.ERROR_RESTART_REGENERATE)
  }
  };

  const MobileRender = () => {
    return (
      <div>
        <p>{bbaiCYOAPromptOptions["COMPLETE_MOBILE"].prompt}</p>
        <AnimatedButton text={"Play Bit!"} handleClick={playBit} />
      </div>
    );
  };

  const playBit = () => {
    // sendCustomEvent("play_bit")
    handleClickDownloadBBDOC(FileType.BBDOC);
    // window.location.replace( `${Env.BB_DEEP_LINK}${bbdocBitId ? bbdocBitId : ""}`);
  }

  const DesktopRender = () => {
    return (
      <div>
        <div style={{ textAlign: "center"}}>
          With Buildbox World you can explore and play exciting bits…
          <div style={{margin: "12px"}}/>
          <img src={Env.QR_BB_WORLD} alt="buildbox world qr"/>
        </div>
        <p>{bbaiCYOAPromptOptions["COMPLETE"].prompt}</p>
        <ol>
          <li>
            Download and install Buildbox Classic:
            <br />{" "}
            <div className={downloadStyles.downloadLink}>
              <img className={downloadStyles.downloadLinkIcon} width={18} src={"https://frontend-assets.buildbox.com/web-gen-ai/BuildboxClassicLogo.svg"} alt="Buildbox Classic Installer" /> <a href="/#" onClick={() => handleClickDownloadBBDOC(FileType.BBCLASSIC_INSTALLER)}>Buildbox Classic Installer</a>
            </div>
          </li>
          <li>
            Download your project file:
            <br />{" "}
            <div className={downloadStyles.downloadLink}>
              <img className={downloadStyles.downloadLinkIcon} width={18} src={"https://frontend-assets.buildbox.com/web-gen-ai/bbdoc@2x.png"} alt="StoryGame.bbdoc" /> <a href="/#" onClick={() => handleClickDownloadBBDOC(FileType.BBDOC)}>Your game.bbdoc</a>
            </div>
          </li>
          <li>Open the .bbdoc file in Buildbox Classic to edit it today!</li>
        </ol>
      </div>
    );
  };
  
  return (
    <>
     <Row
        className={`${styles.promptMessage} ${styles.promptFeedbackBBAI}`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={bbaiUser.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
          {isMobile ? <MobileRender /> : <DesktopRender/>}
        </Col>
      </Row>
    </>
  )
}
