import { Col, Row } from 'antd'
import { bbaiUser } from '../../../constants/bbai.prompts'
import styles from "../../../styles/PromptMessage.module.css";
import useNewCYOAStore from '../../../newCYOAStore'
import { v4 as uuidv4 } from "uuid";

enum ClickOptionTypes {
  RESTART = "RESTART",
  REGENERATE = "REGENERATE",
}
const ErrorRestartRegenerateMessage = () => {
  const bbaiStore = useNewCYOAStore()
  const handleClick = (option: ClickOptionTypes) => {
    console.log("optionClick", option)

    switch(option){
      case ClickOptionTypes.RESTART:
        // bbaiStore.setPromptStepState(bbaiCYOAPromptStepStatus.INITIAL)
        bbaiStore.startOver()
        break;
      case ClickOptionTypes.REGENERATE:
        // bbaiStore.setStage(bbaiCYOAPromptStepStatus.GENERATING_GAME_DATA)
        const newUUID = uuidv4();
        bbaiStore.setRetryID(newUUID)
        break;
      default:
        console.debug("ErrorRestartRegenerateMessage: handleClick: default")
        break;
    }
  }

  return (
    <>
      <Row
        className={`${styles.promptMessage} ${ styles.promptFeedbackBBAI}`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={bbaiUser.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
           <p>
           You caught our AI on a work break 	&#x1F614; . Give them a few minutes and then <a href="#/" onClick={() => {
          handleClick(ClickOptionTypes.RESTART)

        }}>Start Over</a> or <a href="#/" onClick={() => {
          handleClick(ClickOptionTypes.REGENERATE)
        }}> Retry</a>
      </p>
        </Col>
      </Row>
    </>
  )
}

export default ErrorRestartRegenerateMessage