import { FunctionComponent } from "react";
import useIsMobile from "../../../hooks/useIsMobile";

export enum ClaimLoginSkipOptionsType {
  CLAIM = "CLAIM",
  SKIP = "SKIP",
  LOGIN = "LOGIN",
}

interface ClaimLoginSkipOptionsProps {
  handleClick?: (opType: ClaimLoginSkipOptionsType) => void;
}

const ClaimLoginSkipOptions: FunctionComponent<ClaimLoginSkipOptionsProps> = ({
  handleClick,
}) => {
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile ? (
        <>
          <p>
            I'm ready to upload your game to Buildbox World so you can play it
            on your device now!
          </p>
          <p>
            To Continue:{" "}
            <a
              href="#/"
              onClick={() => handleClick?.(ClaimLoginSkipOptionsType.CLAIM)}
            >
              CLAIM
            </a>{" "}
            this guest experience as your own,{" "}
            <a
              href="#/"
              onClick={() => handleClick?.(ClaimLoginSkipOptionsType.LOGIN)}
            >
              LOGIN
            </a>{" "}
            to save to your existing account, or{" "}
            <a
              href="#/"
              onClick={() => handleClick?.(ClaimLoginSkipOptionsType.SKIP)}
            >
              SKIP
            </a>{" "}
            to download your BBDoc manually now
          </p>
        </>
      ) : null}
    </>
  );
};

export default ClaimLoginSkipOptions;
