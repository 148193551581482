import { Col, Row } from "antd";
import { NavButtons } from '../../components/NavButtons';
import styles from "../../styles/PlaceholderFormComponent.module.css";
import useIsMobile from '../../hooks/useIsMobile';
import PromptForm from './components/PromptForm';

export interface Props {
  store: any; // AP: this looks hopeless already
  unauthorizedErrorHandler?: () => void;
  onClaimRequest: (createAccountMode: boolean) => void;
  propagateLogout?: () => void;
}

const GenAIPrompt = (props: Props) => {

  const { onClaimRequest, propagateLogout, store } = props;
  const bbaiStore = store
  // const [api, contextHolder] = notification.useNotification();
  const isMobile = useIsMobile();

  // const openNotificationWithIcon = () => {
  //   api["error"]({
  //     message: bbaiStore.error?.title,
  //     description: bbaiStore.error?.message,
  //   });

  //   // bbaiStore.setErrorState(undefined);
  // };

  return (
    <>
      {/* {contextHolder} */}
      {/* {bbaiStore.error !== undefined && openNotificationWithIcon()} */}
      <Row className={styles.container}>
        {!isMobile && <Col xs={24} md={7}></Col>}
        <Col className={styles.container} xs={24} md={24} lg={10}>
          <div className={styles.promptFormContainer}>
            <NavButtons />
            <PromptForm onClaimRequest={onClaimRequest} propagateLogout={propagateLogout} store={bbaiStore} />
            {/* {formRender()} */}
          </div>
        </Col>
        {!isMobile && (
          <Col className={styles.container} xs={24} md={7}>
            <div className={styles.dispText}>
              <div className={styles.brandingLogoContainer}>
                <img src="https://frontend-assets.buildbox.com/bb-transparent-logo-white.png" className={styles.brandingLogo} alt="bb-logo"/>
              </div>
              <h1>AI for Dreamers</h1>
              <p>Make Games, Make Money Using AI</p>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}

export default GenAIPrompt